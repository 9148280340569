$(".login-form").validate({
    rules: {
        'email': {
            required: true,
            email: true
        },
        'password': {
            required: true
        }
    },
    messages: {
        'email': {
            required: "Lütfen mail adresinizi giriniz.",
            email: "Lütfen geçerli bir mail adresinizi giriniz."
        },
        'password': {
            required: "Lütfen şifrenizi giriniz."
        }
    },
    errorElement: "span",
    errorPlacement: function (error, element) {
        error.addClass("help-block");

        if (element.prop("type") === "checkbox") {
            error.insertAfter(element.parents("label"));
        }
        else {
            error.insertAfter(element);
        }
    },
    highlight: function (element, errorClass, validClass) {
        $(element).parents('.form-group').addClass("has-error").removeClass("has-success");
    },
    unhighlight: function (element, errorClass, validClass) {
        $(element).parents('.form-group').addClass("has-success").removeClass("has-error");
    }
});