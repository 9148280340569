$(function () {
    $('.newspaper-page-slider').slick({
        infinite: true,
        slide: 'div',
        slidesToShow: 1,
        autoplay: false,
        lazyLoad: 'ondemand',
        fade: true,
        prevArrow: '<a href="javascript:;" class="slide-arrow prev"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
        nextArrow: '<a href="javascript:;" class="slide-arrow next"><i class="fa fa-angle-right" aria-hidden="true"></i></a>'
    });

    $(".newspaper-nav ul li").click(function(e){
        e.preventDefault();
        $(".newspaper-nav ul li").removeClass('active');
        $(this).addClass('active');
        slideIndex = $(this).index();
        $('.newspaper-page-slider').slick('slickGoTo', slideIndex, true);
    });

    $.fn.datepicker.dates['tr'] = {
        days: ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"],
        daysShort: ["Pz", "Pzt", "Sal", "Çrş", "Prş", "Cu", "Cts"],
        daysMin: ["Pz", "Pzt", "Sa", "Çr", "Pr", "Cu", "Ct"],
        months: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],
        monthsShort: ["Oca", "Şub", "Mar", "Nis", "May", "Haz", "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"],
        today: "Bugün",
        clear: "Temizle"
    };

    if(date_type) {
        $('#publish-date').datepicker({
            todayHighlight: true,
            language: "tr",
            startDate: '1987-01-01',
            endDate: end_date,
            todayBtn: true,
            datesDisabled: disabled_dates,
            defaultViewDate: selected_date
        }).on('changeDate', function (e) {

            $('#publish-date').hide();
            $.LoadingOverlay("show");
            var selectedDate = $(this).val();
            window.location = '/' + category_sef + '/date/' + selectedDate;

        });
    }

    /*
    $('a').click(function(e){
        var fancy = $(this).data('fancybox');
        if($(this).attr('href') != undefined && fancy == undefined && $(this).attr('href') != 'javascript:;'){
            $.LoadingOverlay("show");
        }
    });
    $('form').submit(function(){
        $.LoadingOverlay("show");
    });
    */

    $('.date-holder span').click(function () {
       $('#publish-date').focus();
    });

    $("#select-subscribe-month input:radio").change(function(){

        /*
        var monthly = $("#select-subscribe-month input:radio:checked").val();
        var total = (monthly_price * monthly);
        $('#total').html((Math.round(total*100)/100).toFixed(2) + ' TL');
        */
        var amount = $("#select-subscribe-month input:radio:checked").data('amount');
        $('#total').html((Math.round(amount*100)/100).toFixed(2) + ' TL');

    });

    $("#select-payment-type input:radio").change(function(){

        var purchase_method = $("#select-payment-type input:radio:checked").val();
        $('.purchase-method').css('display', 'none');
        $('#purchase-method-' + purchase_method).css('display','');

    });


    $('.mobile-menu-opener').click(function () {
       $('.mobile-menu').slideToggle('fast');
    });

    $(":input").inputmask();



    if($('.other-newspapers .holder').width() < $('.other-newspapers ul').width()) {
        $('.other-newspapers .holder').addClass('scroll-active');
    }
});
